<template>
  <div class="container">暂无信息</div>
</template>

<script>

export default {
  name: "About",
  setup() {
    return {};
  },
};
</script>

<style scoped>
.container {
  width: 30%;
  text-align: center;
}
</style>

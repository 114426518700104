<template>
  <div class="container">
    <div class="header">
      <img src="../../assets/JumpVideo.png" />
      <h2>{{ HEADER_TEXT_MAP.title }}</h2>
      <content class="jv-content">{{ HEADER_TEXT_MAP.content }}</content>
      <el-button type="primary" @click="handleDownLoad">下载</el-button>
    </div>
    <div class="content">
      <div
        v-for="(content, index) in CONTENT_TEXT"
        :key="index"
        class="contentBox"
      >
        <h2 class="contentBoxTitle">{{ content.title }}</h2>
        <div class="contentBoxContent">{{ content.content }}</div>
        <el-button
          type="primary"
          v-if="!!content.seeMore"
          @click="handleTurnToVideo(content.seeMore)"
          >查看更多</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { CONTENT_TEXT, HEADER_TEXT_MAP } from "../../config/JumpVideo/index";

export default {
  name: "App",
  setup() {
    const handleDownLoad = () => {
      window.open("https://l7i7qc3txc.feishu.cn/wiki/IXQ6w38Z4iBFN8kjnJfcQPCInAd?fromScene=spaceOverview");
    };
    const handleTurnToVideo = (url) => {
      window.open(url);
    };
    return {
      CONTENT_TEXT,
      HEADER_TEXT_MAP,
      handleDownLoad,
      handleTurnToVideo,
    };
  },
};
</script>

<style scoped>
.contentBox {
  width: 350px;
  text-align: center;
}
.content {
  display: flex;
  gap: 60px;
  justify-content: center;
  flex-wrap: wrap;
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 80px;
}
.header {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.header .el-button {
  margin-top: 20px;
}

.content .el-button {
  margin-top: 20px;
}
.jv-content {
  width: 24rem;
}
</style>

export const CONTENT_TEXT = {

  supportNote: {
    title: '支持所有笔记软件',
    content: '支持市面上的主流笔记软件:Obsidian，思源笔记，Logseg,Flomo, Notion, XMind ,RoamEdit, RemNote, BookxNote,ithoughts，幕布，葫芦笔记，TheBrain等.',
    seeMore: 'https://space.bilibili.com/351215045?spm_id_from=333.1007.0.0'
  },
  supportOnlineVideo: {
    title: '支持所有在线视频和网盘',
    content: '已经测试支持哔哩哔哩、阿里网盘、天翼网盘、夸克网盘、迅雷网盘、腾讯微云、115网盘、百度网盘、Alist、cloudDrive2、Mooc、YouTube、Ted、网易公开课、微信视频、知乎、抖音、快手、小红书、微博、西瓜视频、今日头条、搜狐视频、腾讯视频、优酷视频、爱奇艺视频、芒果TV、咪咕视频，PP视频.'
  },
  enableMove: {
    title: '傻瓜操作数据可迁移',
    content: '傻瓜式操作，仅需两个快捷键就能获取时间戳链接和截图，做好的笔记可以迁移到另一台电脑或者另一款笔记软件，任何时间任何地点都能回到视频对应的时间点中，时间戳链接永不失效!',
    seeMore: 'https://space.bilibili.com/351215045?spm_id_from=333.1007.0.0'
  },
}

export const HEADER_TEXT_MAP = {
  title: 'JumpVideo',
  content: 'JumpVideo是一款专门为边看视频边做笔记的软件，独创的时间戳链接技术搭配浏览器/PotPlayer/MPV/VLC播放器可以为众多格式的视频做笔记。傻瓜式操作，小白入门无难度。看视频做笔记，就用JumpVideo'
}



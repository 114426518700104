import { createRouter, createWebHistory } from 'vue-router';
import JumpVideo from "../components/JumpVideo/index.vue";
import About from "../components/About.vue"

const routes = [
  { path: '/', name: 'JumpVideo', component: JumpVideo },
  { path: '/about', name: 'About', component: About },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;

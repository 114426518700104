<template>
  <div class="container">
    <header class="navbar">
      <span class="navItem" @click="navigateTo('/')"> 主页 </span>
      <span class="navItem">
        <el-dropdown trigger="click">
          <span class="el-dropdown-link">
            产品<el-icon><arrow-down /></el-icon>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="navigateTo('/')" :icon="Plus"
                >JumpVideo</el-dropdown-item
              >
              <el-dropdown-item
                @click="handleTurnToBiyi"
                :icon="CirclePlusFilled"
                >BiYi</el-dropdown-item
              >
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </span>
      <span @click="navigateTo('/about')" class="navItem"> 关于 </span>
    </header>
    <router-view></router-view>
    <footer>
      <div class="container">
        <p>
          Copyright&nbsp;©&nbsp;2024
        </p>
        <p>
          <a href="https://beian.miit.gov.cn" target="_blank" rel="noopener"
            >桂ICP备2024024525号-1</a>TGK的宝藏
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
import {
  ElDropdown,
  ElDropdownMenu,
  ElDropdownItem,
  ElIcon,
} from "element-plus";
import { ArrowDown } from "@element-plus/icons-vue";
import { useRouter } from "vue-router";

export default {
  name: "App",
  components: {
    ElDropdown,
    ElIcon,
    ElDropdownMenu,
    ElDropdownItem,
    ArrowDown,
  },
  setup() {
    const router = useRouter();

    const handleTurnToBiyi = () => {
      window.open("http://biyi.thegodofking.com");
    };
    const navigateTo = (path) => {
      router.push(path);
    };
    return {
      navigateTo,
      handleTurnToBiyi,
    };
  },
};
</script>
<style scoped>
.navbar {
  position: fixed;
  width: 100%;
  display: flex;
  top: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  gap: 20px;
  padding: 10px;
  background-color: #fff;
}
.navbar .el-dropdown {
  font-size: 16px;
  color: #2c3e50;
}
.navItem {
  cursor: pointer;
}
.navItem:hover {
  color: #409eff !important;
}
footer {
    margin-top: 10px;
    padding: 10px;
    color: rgba(0, 0, 0, .65);
    border-top: 1px solid rgba(120, 130, 140, .13);
}
footer .container {
      padding: 5px 0;
    }
    footer .container p {
      line-height: 10px;
    }
  footer .container a {
    color: rgba(0, 0, 0, .65);
    text-decoration: none;
  }
</style>
